.container {
    margin: 50px auto;
    padding: 20px;
    background-color: #eefef2;
    border-radius: 12px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  .search {
    display: flex;
    align-items: center;
    gap: 10px; 
  }
  
  input[type="text"] {
    padding: 10px 15px;
    border: 1px solid #ccc; 
    font-size: 1rem;
    border-radius: 4px;
    width: 100%;
  }
  
  button {
    padding: 10px 15px;
    background-color: #66bb6a;
    color: white; 
    border: none;
    border-radius: 4px;
    font-family: inherit; 
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #43a047;
  }
  
  ul {
    list-style: none; 
    padding: 0;
    margin: 10px 0;
  }
  
  li {
    margin-bottom: 5px;
    display: flex;
    gap: 24px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    align-content: center;
    flex-wrap: wrap;
  }