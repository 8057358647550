
* {
  text-align: center;
  box-sizing: border-box;
}

body {
  background-image: url("stuff.jpg");
  font-family: 'Arial', sans-serif;
  background-color: #e8f5e9; 
  color: #333;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  animation: fadeIn 1s ease-in-out;
}

.wrapper {
  height: 87vh;
  max-width: 1000px;
  margin: 50px auto;
  padding: 20px;
  background-color: #eefef2; 
  border-radius: 12px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  animation: slideIn 1s ease-in-out;
}

h1 {
  margin-bottom: 20px;
  color: #2e7d32; 
  font-size: 4rem;
  font-weight: 600;
  font-family: 'Andale Mono', monospace;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* align-items: center; */
}

.file-input-group {
  display: flex;
  align-items: center;
  gap: 300px; /* Adds space between the file input and download button */
  margin-bottom: 20px;
}
input[type="file"] {
  padding: 20px;
  border: 2px dashed #81c784; 
  font-size: 1.5rem;
  border-radius: 4px;
  background-color: #e0e0b0;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  transition: border-color 0.3s ease, transform 0.2s ease;
}

input[type="file"]:hover {
  border-color: #4caf50; 
  transform: scale(1.05); 
}
.download-button {
  padding: 15px 30px;
  background-color: #66bb6a;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 8px;
  font-family: 'Andale Mono', monospace;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.download-button:hover {
  background-color: #43a047;
  transform: translateY(-3px);
}
.button-group {
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  /* justify-content: space-between; */
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: -5px;
  /* margin: 20px 0 20px -40px; */
  gap: 20px; 
}

.btn-left, .btn-right {
  padding: 15px 30px;
  background-color: #66bb6a; 
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 8px;
  font-family: 'Andale Mono', monospace;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.btn-left:hover, .btn-right:hover {
  background-color: #43a047;
  transform: translateY(-3px);  
}

p {
  margin-top: 20px;
  font-size: 1rem;
  color: #daaaaa;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}
